import React, { useState, useEffect, useRef } from "react";
import MaterialTable from "material-table";
import {
  Box,
  useDisclosure,
  Button,
  Checkbox,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Flex,
} from "@chakra-ui/react";
import { getComplains } from "../../../redux/slices/Admin";
import { useDispatch, useSelector } from "react-redux";
import Form from "./components/form";
import DeleteConfirmation from "./components/DeleteConfirmation";
import { axiosDelete } from "../../../redux/services/api";
import { rootUrl } from "../../../utilities/constants";
import { ToastContainer, toast } from "react-toastify";
import { useComplain, useMutationDelete } from "./hooks";
import { useQueryClient } from "react-query";

import {
  AsyncCreatableSelect,
  AsyncSelect,
  CreatableSelect,
  Select,
} from "chakra-react-select";
const columns = [
  { title: "Issue", field: "issue" },
  { title: "Assign to", field: "user.name" },
  { title: "Location", field: "location.name" },
  { title: "Priority", field: "ticketPriority.name" },

  {
    title: "status",
    field: "ticketStatus.name",
  },
  { title: "created By", field: "createdBy" },
];
export default function Index() {
  const { lcationsList } = useSelector((state) => state.Admin);
  const [selectedLocation, setSelectedLocation] = useState(null);
  // const selectedLocationRef = useRef(null);
  // selectedLocationRef.current = selectedLocation;
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isPending } = useMutationDelete();

  const { data, isLoading, isSuccess, isError, error, isFetching, refetch } =
    useComplain({
      PageNumber: 0,
      PageSize: 100,
      locationId: selectedLocation?.value,
    });

  useEffect(() => {
    // console.log({ isFetching, data, isSuccess, isError, error });
  }, [isFetching, data, isSuccess, isError, error]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpendelete,
    onClose: onClosedelete,
  } = useDisclosure();

  const { jwtToken } = useSelector((state) => state.Auth);
  const [editRows, setEditRows] = useState({});
  const [rows, setRows] = useState([]);
  const [deleteLoading, setdeleteLoading] = useState(false);
  useEffect(() => {
    // console.log({ lcationsList });
    // fetchList(selectedLocation);
  }, []);
  // useEffect(() => {
  //   if (ComplainsList) {
  //     console.log({ ComplainsList, jwtToken, RoleList, ApplicationList });
  //     setRows(structuredClone(ComplainsList));
  //   }
  // }, [ComplainsList]);
  const fetchList = (selectedLocation) => {
    let payload = {
      PageNumber: 0,
      PageSize: 100,
      locationId: selectedLocation,
    };
    // dispatch(getComplains(payload));
  };
  const Add = () => {
    onOpen();
    setEditRows(null);
  };
  const edit = (rowData) => {
    setEditRows(rowData);
    console.log({ rowData });
    onOpen();
  };
  const deleteRec = (rowData) => {
    onOpendelete();
    setEditRows(rowData);
  };
  const deleteConfirm = async () => {
    mutateAsync(editRows.id).then(() => {
      onClosedelete();
      refetch();
      toast("Complain Deleted Successfully", {
        type: "success",
      });
    });
    setdeleteLoading(true);
    // let response = await axiosDelete(
    //   `${rootUrl}/v1/Location/Delete/${editRows.id}`
    // );
    // setdeleteLoading(false);
    // if (response?.data?.success) {
    //   // fetchList();
    //   onClosedelete();
    //   toast("Location Deleted Successfully", {
    //     type: "success",
    //   });
    // }
  };
  return (
    <Box
      mx={{ base: "auto", lg: "0px" }}
      me="auto"
      h="100%"
      alignItems="start"
      justifyContent="center"
      mb={{ base: "30px", md: "60px" }}
      px={{ base: "25px", md: "0px" }}
      mt={{ base: "40px", md: "14vh" }}
      style={{ maxWidth: "100%" }}
    >
      <Flex bg="white" borderTopRadius={"4px"} justifyContent={"flex-end"}>
        <FormControl px="10px" zIndex={100} w="300px">
          <FormLabel fontSize="sm" fontmaxWeight="500">
            Locations
          </FormLabel>
          <Select
            colorScheme="purple"
            options={lcationsList.map((x) => {
              return { label: x.name, value: x.id };
            })}
            onChange={(e) => {
              console.log({ e });
              setSelectedLocation(e);
              // refetch();
            }}
            value={selectedLocation}
          />
        </FormControl>
      </Flex>
      <MaterialTable
        isLoading={isLoading || isFetching}
        title="Complains List"
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
        }}
        columns={columns}
        data={data?.result ?? []}
        actions={[
          {
            icon: "add",
            tooltip: "Add User",
            isFreeAction: true,
            onClick: (event) => Add(),
          },
          (rowData) => ({
            icon: "edit",
            tooltip: "edit",
            onClick: (event, rowData) => edit(rowData),
          }),
          (rowData) =>
            (rowData?.ticketHistory?.length===0 && rowData?.ticketNote?.length===0 ) 
              ? {
                  icon: "delete",
                  tooltip: "delete",
                  onClick: (event, rowData) => deleteRec(rowData),
                }
              : null,
        ]}
      />
      <DeleteConfirmation
        deleteLoading={deleteLoading}
        isOpen={isOpenDelete}
        onOpen={onOpendelete}
        onClose={onClosedelete}
        deleteConfirm={deleteConfirm}
      />
      {isOpen && (
        <Form
          editRows={editRows}
          fetchList={fetchList}
          onClose={onClose}
          isOpen={isOpen}
          refetch={refetch}
          selectedLocation={selectedLocation?.value}
        />
      )}
    </Box>
  );
}
