import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post, get } from "../services/api";
// import { ProductInterface } from "../../utilities/Interface";
import { ToastContainer, toast } from "react-toastify";
import { rootUrl, hubRootUrl, eventRootUrl } from "../../utilities/constants";
export const getLocations = createAsyncThunk("Admin/Location", async (data) => {
  console.log("getLocations");
  try {
    let url = `${rootUrl}/v1/Location?PageSize=50&PageNumber=0`;

    const response = await get(url);
    console.log({ response: response.data.result });
    if (response.data.success) {
      return response.data.result;
    } else {
      throw response.data.errorMessage;
    }
  } catch (err) {
    // //console.log({ errorz: err.response?.data?.error_description });
    console.log({ errorz: err.response.data.message });
    toast(err.response.data.message, {
      type: "error",
    });
    if (err) {
      throw err.response.data.message;
    } else {
      throw err.response.data.message;
    }
  }
});
export const getUsers = createAsyncThunk("Admin/User", async (data) => {
  console.log("getUser");
  try {
    let url = `${rootUrl}/v1/User?PageSize=50&PageNumber=0`;

    const response = await get(url);
    console.log({ response: response.data.result });
    if (response.data.success) {
      return response.data.result;
    } else {
      throw response.data.errorMessage;
    }
  } catch (err) {
    // //console.log({ errorz: err.response?.data?.error_description });
    console.log({ errorz: err.response.status });
    toast(err.response.data.message, {
      type: "error",
    });
    if (err) {
      throw err.response.data.message;
    } else {
      throw err.response.data.message;
    }
  }
});
export const getRoles = createAsyncThunk("Admin/Role", async (data) => {
  console.log("getRole/Roles");
  try {
    let url = `${rootUrl}/v1/Role/Roles`;
    const response = await get(url);
    console.log({ response: response.data.result });
    if (response.data.success) {
      return response.data.result;
    } else {
      throw response.data.errorMessage;
    }
  } catch (err) {
    // //console.log({ errorz: err.response?.data?.error_description });
    console.log({ errorz: err.response.status });
    toast(err.response.data.message, {
      type: "error",
    });
    if (err) {
      throw err.response.data.message;
    } else {
      throw err.response.data.message;
    }
  }
});
export const getApplication = createAsyncThunk(
  "Admin/Application",
  async (data) => {
    console.log("getRole/Application");
    try {
      let url = `${rootUrl}/v1/Application`;
      const response = await get(url);
      console.log({ response: response.data.result });
      if (response.data.success) {
        return response.data.result;
      } else {
        throw response.data
      }
    } catch (err) {
      // //console.log({ errorz: err.response?.data?.error_description });
      console.log({ errorApplication: err });
      toast(err, {
        type: "error",
      });
      if (err) {
        throw err;
      } else {
        throw err;
      }
    }
  }
);


export const getComplains = createAsyncThunk("Admin/getComplains", async (payload) => {
  console.log("getComplains");
  const {PageNumber, PageSize, locationId} = payload;
  try {
    let url =`${rootUrl}/v1/Complain?PageSize=${PageSize}&PageNumber=${PageNumber}&LocationId=${locationId}`;
    const response = await get(url);
    console.log({ response: response.data.result });
    if (response.data.success) {
      return response.data.result;
    } else {
      throw response.data.errorMessage;
    }
  } catch (err) {
    // //console.log({ errorz: err.response?.data?.error_description });
    console.log({ errorz: err.response.data.message });
    toast(err.response.data.message, {
      type: "error",
    });
    if (err) {
      throw err.response.data.message;
    } else {
      throw err.response.data.message;
    }
  }
});
const initialState = {
  loadingLcations: false,
  lcationsList: [],
  loadingUsers: false,
  UsersList: [],
  RoleList: [],
  ApplicationList: [],
  ComplainsList: [],
  ComplainsListLoading: false,
};

const AdminSlice = createSlice({
  name: "AdminSlice",
  initialState: initialState,
  reducers: {
    clearResults(state, action) {},
  },
  extraReducers: (builder) => {
    builder.addCase(getComplains.pending, (state) => {
      state.ComplainsListLoading = true;
    });
    builder.addCase(getComplains.fulfilled, (state, action) => {
      state.ComplainsListLoading = false;
      state.ComplainsList = action.payload;
    });
    builder.addCase(getComplains.rejected, (state, error) => {
      state.ComplainsListLoading = false;
    });




    builder.addCase(getLocations.pending, (state) => {
      state.loadingLcations = true;
    });
    builder.addCase(getLocations.fulfilled, (state, action) => {
      state.loadingLcations = false;
      state.lcationsList = action.payload;
    });
    builder.addCase(getLocations.rejected, (state, error) => {
      state.loadingLcations = false;
    });

    builder.addCase(getUsers.pending, (state) => {
      state.loadingUsers = true;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.loadingUsers = false;
      state.UsersList = action.payload;
    });
    builder.addCase(getUsers.rejected, (state, error) => {
      state.loadingUsers = false;
    });

    builder.addCase(getApplication.fulfilled, (state, action) => {
      state.ApplicationList = action.payload;
    });

    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.RoleList = action.payload;
    });
  },
});
export const { clearResults } = AdminSlice.actions;
export default AdminSlice.reducer;
