import axios from "axios";
let store
export const injectStore = _store => {
  store = _store
}
const instance = axios.create({
  headers: {
    // 'Content-Type': 'application/x-www-form-urlencoded',
    'X-ApplicationIDHeader': "1"
  },
});

instance.interceptors.request.use((config) => {
   
  
  config.headers.Authorization = `Bearer ${store.getState().Auth.jwtToken}`;
  return config;
});

export default instance;