import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post, get } from "../services/api";
// import { ProductInterface } from "../../utilities/Interface";
import { ToastContainer, toast } from "react-toastify";

import { rootUrl, hubRootUrl, eventRootUrl } from "../../utilities/constants";
export const Authenticate = createAsyncThunk("auth/token", async (data) => {
  try {
    let payload = {
      data: data,
      url: `${rootUrl}/v1/User/Login`,
    };
    const response = await post(payload);
    console.log({ response: response.data.result });
    if (response.data.success) {
      return response.data.result;
    } else {
      throw response.data.errorMessage;
    }
  } catch (err) {
    // //console.log({ errorz: err.response?.data?.error_description });
    console.log({ errorz: err.response.data.message });
    toast(err.response.data.message, {
      type: "error",
    });
    if (err) {
      throw err.response.data.message;
    } else {
      throw err.response.data.message;
    }
  }
});

const initialState = {
  loading: false,
  isAuthenticated: false,
  email: "",
  firstName: "",
  fullName: "",
  id: "",
  jwtToken: "",
  lastName: "",
  refreshToken: "",
  rolePermission: "",
  tokenExpiry: "",
  type: "",
  userApplicationRoles: [],
};

const AuthSlice = createSlice({
  name: "AuthSlice",
  initialState: initialState,
  reducers: {
    clearResults(state, action) {
      state.AuthError = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(Authenticate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Authenticate.fulfilled, (state, action) => {
      console.log({ action: action.payload });
      state.isAuthenticated = true;
      state.loading = false;
      state.email = action.payload.email;
      state.firstName = action.payload.firstName;
      state.fullName = action.payload.fullName;
      state.id = action.payload.id;
      state.jwtToken = action.payload.jwtToken;
      state.lastName = action.payload.lastName;
      state.refreshToken = action.payload.refreshToken;
      state.rolePermission = action.payload.rolePermission;
      state.tokenExpiry = action.payload.tokenExpiry;
      state.type = action.payload.type;
      state.userApplicationRoles = action.payload.userApplicationRoles;
    });
    builder.addCase(Authenticate.rejected, (state, error) => {
      state.AuthError = error;
      state.loading = false;
    });
  },
});
export const { clearResults } = AuthSlice.actions;
export default AuthSlice.reducer;
