// DEVELOPMENT

export const FORMS_ID = {
  Analytics: "1",
  Profile: "2",
  Settings: "3",
  Admins: "6",
  Event: "7",
  HubsList: "8",
  Users: "9",
  UsersRoles: "12",
  TicketingSystem: "14",
};

export const environment = "Dev";
export const rootUrl = "https://waterapp.vivobyte.com/api";
 

// STAGING
// export const environment='Staging'
// export const rootUrl = "https://grid-auth-webapi-stg.azurewebsites.net/";
// export const gameRootUrl = "https://grid-game-webapi-stg.azurewebsites.net/";
// export const eventRootUrl = "https://grid-event-webapi-stg.azurewebsites.net/";
// export const hubRootUrl = "https://grid-hub-api-stg.azurewebsites.net/";
// export const hubAdminUrl = "https://grid-user-webapi-stg.azurewebsites.net/";

// PRODUCTION
// export const environment=''
// export const rootUrl = "https://grid-auth-webapi.azurewebsites.net/";
// export const gameRootUrl = "https://grid-game-webapi.azurewebsites.net/";
// export const eventRootUrl = "https://grid-event-webapi.azurewebsites.net/";
// export const hubRootUrl = "https://grid-hub-api.azurewebsites.net/";
// export const hubAdminUrl = "https://grid-user-webapi.azurewebsites.net/";
