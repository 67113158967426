import React, { useState, useEffect } from "react";
import {
  Box,
  ButtonGroup,
  Radio,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useToast,
} from "@chakra-ui/react";
import {
  CheckboxContainer,
  CheckboxControl,
  CheckboxSingleControl,
  InputControl,
  NumberInputControl,
  PercentComplete,
  RadioGroupControl,
  ResetButton,
  SelectControl,
  SliderControl,
  SubmitButton,
  SwitchControl,
  TextareaControl,
} from "formik-chakra-ui";
import { post, put } from "../../../../redux/services/api";
import { rootUrl } from "../../../../utilities/constants";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { getLocations } from "../../../../redux/slices/Admin";
import { useDispatch, useSelector } from "react-redux";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const validationSchema = Yup.object({
  name: Yup.string().required().min(3),
  abbreviation: Yup.string().required().min(2),
  address: Yup.string().required().max(100),
  phone: Yup.string().required().min(11),
  notes: Yup.string().required(),
  status: Yup.boolean().equals([true]),
});

const Form = (props) => {
  const dispatch = useDispatch();

  //   const toast = useToast();
  const { onClose, isOpen, editRows } = props;
  const initialValues = {
    name: "",
    abbreviation: "",
    address: "",
    phone: "",
    notes: "",
    status: true,
  };
  const [isLoading, setisLoading] = useState(false);

  const onSubmit = async (values) => {
    console.log({ values: values.name });
    if (!values.name) {
      return;
    }

    setisLoading(true);

    let response;
    if (editRows) {
      delete values.updatedAt;
      delete values.createdAt;
      delete values.updatedBy;
      delete values.createdBy;
      // delete values.tableData;

      let payload = {
        url: `${rootUrl}/v1/Location/${editRows.id}`,
        data: values,
      };
      response = await put(payload);
    } else {
      let payload = {
        url: `${rootUrl}/v1/Location`,
        data: values,
      };
      response = await post(payload);
    }

    setisLoading(false);
    if (response?.data?.success) {
      console.log({
        response: response.data.message,
        fetchLocations: fetchLocations,
      });
      fetchLocations();
      onClose();
      if (editRows) {
        toast("Location Updated Successfully", {
          type: "success",
        });
      } else {
        toast("Location Created Successfully", {
          type: "success",
        });
      }
    }
  };
  const fetchLocations = () => {
    dispatch(getLocations());
  };
  //   console.log({ onSubmit });
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>{editRows ? "Update" : "Create"} Location</DrawerHeader>
        <DrawerBody>
          <Formik
            initialValues={editRows ? editRows : initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, values, errors, touched }) => (
              <Box p={"10px"} as="form" onSubmit={handleSubmit}>
                <InputControl name="name" label="Name" />
                <InputControl name="abbreviation" label="Abbreviation" />
                <InputControl name="address" label="Address" />
                <InputControl name="phone" label="Phone" />
                <TextareaControl name="notes" label="Notes" />
                <CheckboxSingleControl mt="20px" name="status">
                  Active
                </CheckboxSingleControl>
                <DrawerFooter>
                  <Button onClick={onClose} variant="outline" mr={3}>
                    Cancel
                  </Button>
                  <ResetButton>Reset</ResetButton>
                  <Button
                    isLoading={isLoading}
                    ml="10px"
                    type="submit"
                    onClick={onSubmit}
                    colorScheme="blue"
                  >
                    Save
                  </Button>
                </DrawerFooter>
              </Box>
            )}
          </Formik>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default Form;
