import axios from "./axios";

export const get = async (url, options) => await axios.get(url, options);
export const axiosDelete = async (url) => await axios.delete(url);


// export const get = (url, options) => {
//   console.log({url})
//   try {
//     return axios.get(url, options);
//   } catch (err) {
//     if (err.response.status === 401) {
//     } else {
//       throw err;
//     }
//   }
// };
// export const post = async (payload) => await axios.post(payload.url,  {});

export const post = (payload) => {
  try {
    return axios.post(payload.url, payload.data);
  } catch (err) {
    console.log({ err });
    if (err.response.status === 401) {
    } else {
      throw err;
    }
  }
};
export const put = (payload) => {
  try {
    return axios.put(`${payload.url}`, payload.data);
  } catch (err) {
    if (err.response.status === 401) {
    } else {
      throw err;
    }
  }
};

// export const deleteAxios = (payload) => {
//   try {
//     return axios.delete(payload.url);
//   } catch (err) {
//     if (err.response.status === 401) {
//     } else {
//       throw err;
//     }
//   }
// };
export const deleteAxios = async (url, options) => await axios.delete(url,options);