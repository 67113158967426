import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdSettingsAccessibility,
  MdGasMeter,
  MdSpeaker,
  MdList
} from "react-icons/md";

// Admin Imports
import locations from "views/admin/locations";
import users from "views/admin/users";
import complains from "views/admin/complains";


// Auth Imports
import SignInCentered from "views/auth/signIn";

const routes = [
  {
    name: "Locations",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: locations,
    isMenue: true,
    // category: true,
    // items: [
    //   {
    //     name: "Sign In",
    //     layout: "/auth",
    //     path: "/sign-in",
    //     icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    //     component: SignInCentered,
    //     isMenue: false,
    //   },
    // ],
  },
  {
    name: "Users",
    layout: "/admin",
    path: "/users",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: users,
    isMenue: true,
  },
  {
    name: "Complains",
    layout: "/admin",
    path: "/complain",
    icon: <Icon as={MdSettingsAccessibility} width="20px" height="20px" color="inherit" />,
    component: complains,
    isMenue: true,
  },
  {
    name: "Readings",
    layout: "/admin",
    path: "/readings",
    icon: <Icon as={MdGasMeter} width="20px" height="20px" color="inherit" />,
    component: complains,
    isMenue: true,
  },
  {
    name: "Consumables",
    layout: "/admin",
    path: "/consumables",
    icon: <Icon as={MdSpeaker} width="20px" height="20px" color="inherit" />,
    component: complains,
    isMenue: true,
  },
  {
    name: "Attendance",
    layout: "/admin",
    path: "/attendance",
    icon: <Icon as={MdList} width="20px" height="20px" color="inherit" />,
    component: complains,
    isMenue: true,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
    isMenue: false,
  },
];

export default routes;
