import { useQuery, useMutation, useQueryClient } from "react-query";
import { post, put, get, deleteAxios } from "../../../redux/services/api";
import { rootUrl } from "../../../utilities/constants";
// const queryClient = useQueryClient()

const fetchComplains = async (payload) => {
  const { PageNumber, PageSize, locationId } = payload;
  const { data } = await get(
    `${rootUrl}/v1/Complain?PageSize=${PageSize}&PageNumber=${PageNumber}&LocationId=${locationId}`
  );
  return data;
};

const fetchComplainsById = async (TicketId) => {
  const { data } = await get(`${rootUrl}/v1/Complain?TicketId=${TicketId}`);
  return data;
};

const deleteComplain = async (id) => {
  const { data } = await deleteAxios(`${rootUrl}/v1/Complain/Delete/${id}`);
  return data;
};
const saveNotes = async (payload) => {
  let obj = {
    url: `${rootUrl}/v1/Complain/AddComplainNotes`,
    data: payload,
  };
  const res = await post(obj);
  return res;
};

export const useMutationSaveNotes = () => {
  return useMutation({
    mutationFn: (payload) => saveNotes(payload),
  });
};

export const useMutationDelete = () => {
  return useMutation({
    mutationFn: (id) => deleteComplain(id),
  });
};
export const useFtchComplainById = (TicketId) => {
  return useQuery({
    queryKey: ["fetchComplainById"],
    queryFn: () => fetchComplainsById(TicketId),
    enabled: TicketId ? true : false,
    cacheTime :0
  });
};
export const useComplain = (payload) => {
  return useQuery({
    queryKey: ["fetchComplains",payload],
    queryFn: () => fetchComplains(payload),

    refetchOnWindowFocus: false,
  });
};

// export default useComplain
