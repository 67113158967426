import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { Box, useDisclosure, Button, Checkbox } from "@chakra-ui/react";
import { getUsers } from "../../../redux/slices/Admin";
import { useDispatch, useSelector } from "react-redux";
import Form from "./components/form";
import DeleteConfirmation from "./components/DeleteConfirmation";
import { axiosDelete } from "../../../redux/services/api";
import { rootUrl } from "../../../utilities/constants";
import { ToastContainer, toast } from "react-toastify";

const columns = [
  { title: "First Name", field: "firstName" },
  { title: "Last Name", field: "lastName" },
  { title: "Email", field: "email" },
  { title: "Phone", field: "primaryPhone" },
  {
    title: "status",
    field: "status",
    render: (rowData) => <Checkbox ml="10px" isChecked={rowData.status} />,
  },
];
export default function Index() {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpendelete,
    onClose: onClosedelete,
  } = useDisclosure();
  const { loadingUsers, UsersList,RoleList,ApplicationList } = useSelector((state) => state.Admin);
  const { jwtToken } = useSelector((state) => state.Auth);
  const [editRows, setEditRows] = useState({});
  const [rows, setRows] = useState([]);
  const [deleteLoading, setdeleteLoading] = useState(false);
  useEffect(() => {
    fetchUserList();
  }, []);
  useEffect(() => {
    if (UsersList) {
      console.log({ UsersList, jwtToken,RoleList,ApplicationList  });
      setRows(structuredClone(UsersList));
    }
  }, [UsersList]);
  const fetchUserList = () => {
    dispatch(getUsers());
  };
  const Add = () => {
    onOpen();
    setEditRows(null);
  };
  const edit = (rowData) => {
    setEditRows(rowData);
    console.log({ rowData });
    onOpen();
  };
  const deleteRec = (rowData) => {
    onOpendelete();
    setEditRows(rowData);
  };
  const deleteConfirm = async () => {
    setdeleteLoading(true);
    let response = await axiosDelete(`${rootUrl}/v1/Location/Delete/${editRows.id}`);
    setdeleteLoading(false);
    if (response?.data?.success) {
      fetchUserList();
      onClosedelete();
      toast("Location Deleted Successfully", {
        type: "success",
      });
    }
  };
  return (
    <Box
      mx={{ base: "auto", lg: "0px" }}
      me="auto"
      h="100%"
      alignItems="start"
      justifyContent="center"
      mb={{ base: "30px", md: "60px" }}
      px={{ base: "25px", md: "0px" }}
      mt={{ base: "40px", md: "14vh" }}
      style={{ maxWidth: "100%" }}
    >
      <MaterialTable
        isLoading={loadingUsers}
        title="Users List"
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
        }}
        columns={columns}
        data={rows}
        actions={[
          {
            icon: "add",
            tooltip: "Add User",
            isFreeAction: true,
            onClick: (event) => Add(),
          },

          // (rowData) => ({
          //   icon: "edit",
          //   tooltip: "edit",
          //   onClick: (event, rowData) => edit(rowData),
          // }),
          // (rowData) => ({
          //   icon: "delete",
          //   tooltip: "delete",
          //   onClick: (event, rowData) => deleteRec(rowData),
          // }),
        ]}
      />
      <DeleteConfirmation
        deleteLoading={deleteLoading}
        isOpen={isOpenDelete}
        onOpen={onOpendelete}
        onClose={onClosedelete}
        deleteConfirm={deleteConfirm}
      />
     {isOpen&& <Form
        editRows={editRows}
        fetchUserList={fetchUserList}
        onClose={onClose}
        isOpen={isOpen}
      />}
    </Box>
  );
}
