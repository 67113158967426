import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useToast,
  ChevronDownIcon,
  FormErrorMessage,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import {
  CheckboxContainer,
  CheckboxControl,
  CheckboxSingleControl,
  InputControl,
  NumberInputControl,
  PercentComplete,
  RadioGroupControl,
  ResetButton,
  SelectControl,
  SliderControl,
  SubmitButton,
  SwitchControl,
  TextareaControl,
} from "formik-chakra-ui";
import {
  AsyncCreatableSelect,
  AsyncSelect,
  CreatableSelect,
  Select,
} from "chakra-react-select";
import { post, put } from "../../../../redux/services/api";
import { rootUrl } from "../../../../utilities/constants";
import * as Yup from "yup";
import { Formik, Field, FormikContext } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { getUsers } from "../../../../redux/slices/Admin";
import { useDispatch, useSelector } from "react-redux";
import { ArrowUpDownIcon, DeleteIcon } from "@chakra-ui/icons";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const validationSchema = Yup.object({
  firstName: Yup.string().required("First Name must be 3 characters").min(3),
  lastName: Yup.string().required("Last Name must be 3 characters").min(3),
  email: Yup.string().email().required(),
  password: Yup.string()
    .required("No password provided.")
    .min(6, "Password is too short - should be 6 chars minimum."),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
  primaryPhone: Yup.number().required("Phone number is required").min(11),
  notes: Yup.string().required(),
});

const Form = (props) => {
  const formref = useRef();

  const dispatch = useDispatch();
  const { UsersList, RoleList, ApplicationList, lcationsList } = useSelector(
    (state) => state.Admin
  );

  //   const toast = useToast();
  const { onClose, isOpen, editRows } = props;
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    primaryPhone: "",
    notes: "",
  };
  const [localRoleList, setLocalRoleList] = useState([]);
  const [applicationRoles, setApplicationRoles] = useState([]);
  const [applicationRolesArr, setApplicationRolesArr] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [userStatus, setUserStatus] = useState([]);
  const [usersApplication, setUsersApplication] = useState([]);
  const [invalidState, setInvalidState] = useState({
    locationsInvalid: false,
    applicationRolesArrInvalid: false,
  });

  const [isLoading, setisLoading] = useState(false);
  const handlesubmit = () => {
    console.log({ formref });
    if (formref.current) {
      formref.current.handleSubmit();
    }
  };

  const checks = () => {
    console.log({ selectedLocation, applicationRolesArr });
    if (selectedLocation.length === 0) {
      setInvalidState((prvState) => ({ ...prvState, locationsInvalid: true }));
      return false;
    } else if (applicationRolesArr.length === 0) {
      setInvalidState((prvState) => ({
        ...prvState,
        applicationRolesArrInvalid: true,
      }));
      return false;
    }
    return true;
  };
  const onSubmit = async (data) => {
    if (!data.firstName) {
      return;
    }
    if (!checks()) {
      return;
    }
    setisLoading(true);
    let appRolesArr = applicationRolesArr.map((x) => {
      return {
        roleId: x.roleId,
        applicationId: x.applicationId,
      };
    });
    let locationsArr = selectedLocation.map((x) => {
      return {
        locationId: x.value,
      };
    });
    let values = {
      ...data,
      applicationRoles: appRolesArr,
      locations: locationsArr,
    };
    // console.log({ values: values.name });
   
    setisLoading(true);
    let response;
    if (editRows) {
      delete values.updatedAt;
      delete values.createdAt;
      delete values.updatedBy;
      delete values.createdBy;
      // delete values.tableData;
      values = {
        ...values,
        userStatus: { id: userStatus.value },
      };

      let payload = {
        url: `${rootUrl}/v1/User/${editRows.id}`,
        data: values,
      };

      try {
        setisLoading(false);
        response = await put(payload);
      } catch (err) {
        console.log({ err: err.response.data.errors[0] });

        setisLoading(false);
      }
    } else {
      let payload = {
        url: `${rootUrl}/v1/User`,
        data: values,
      };
      try {
        setisLoading(false);
        response = await post(payload);
      } catch (err) {
        let message = err.response.data.errors[0] ?? "";
        console.log({ err: err.response.data.errors[0] });
        toast(message, {
          type: "error",
        });
        setisLoading(false);
      }
    }

    if (response?.data?.success) {
      console.log({
        response: response.data.message,
      });
      fetchList();
      onClose();
      if (editRows) {
        toast("Users Updated Successfully", {
          type: "success",
        });
      } else {
        toast("Users Created Successfully", {
          type: "success",
        });
      }
    }
  };
  const fetchList = () => {
    dispatch(getUsers());
  };
  //   console.log({ onSubmit });

  const addData = () => {
    let arr = [...applicationRolesArr];
    if (applicationRoles.length === 0 && usersApplication.length === 0) {
      return;
    }
    let checks = arr.filter(
      (x) =>
        x.roleId === applicationRoles.value &&
        x.applicationId === usersApplication.value
    )[0];
    if (checks) {
      console.log({ checks });
      return;
      
    }

    setInvalidState((prvState) => ({
      ...prvState,
      applicationRolesArrInvalid: false,
    }));
    setApplicationRoles([]);
    setUsersApplication([]);
    console.log({ applicationRoles, usersApplication });
    let obj = {
      roleId: applicationRoles.value,
      applicationId: usersApplication.value,
      roleName: applicationRoles.label,
      applicationName: usersApplication.label,
    };
    arr.push(obj);
    console.log({ obj });
    setApplicationRolesArr(arr);
  };
  const updateApplication = (x) => {
    setApplicationRoles([]);
    let arr = RoleList.filter((y) => y.applicationId === x.value);
    setLocalRoleList(arr);

    setUsersApplication(x);
  };
  const deleteSelected = (i) => {
    let arr = [...applicationRolesArr];
    arr.splice(i, 1);
    setApplicationRolesArr(arr);
  };
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xl">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>{editRows ? "Update" : "Create"} User</DrawerHeader>
        <DrawerBody>
          <Formik
            innerRef={(f) => (formref.current = f)}
            initialValues={editRows ? editRows : initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, values, errors, touched }) => (
              <Box p={"10px"} as="form" onSubmit={handleSubmit}>
                <Flex>
                  <InputControl name="firstName" label="First Name" />
                  <InputControl ml="10px" name="lastName" label="Last Name" />
                </Flex>
                <Flex>
                  <InputControl name="email" label="Email" />
                  <InputControl
                    ml="10px"
                    name="password"
                    label="Password"
                    inputProps={{
                      type: "password",
                    }}
                  />
                </Flex>
                <Flex>
                  <InputControl
                    name="passwordConfirmation"
                    inputProps={{
                      type: "password",
                    }}
                    label="Password Confirmation"
                  />

                  <InputControl
                    ml="10px"
                    name="primaryPhone"
                    label="Primary Phone No."
                  />
                </Flex>
                <TextareaControl name="notes" label="Notes" />
                <Flex justifyContent={"center"} mt="10px" alignItems={"center"}>
                  <FormControl
                    px="10px"
                    isInvalid={invalidState.locationsInvalid}
                  >
                    <FormLabel fontSize="sm" fontmaxWeight="500">
                      Locations
                    </FormLabel>
                    <Select
                      isMulti
                      colorScheme="purple"
                      options={lcationsList.map((x) => {
                        return { label: x.name, value: x.id };
                      })}
                      onChange={(e) => {
                        setSelectedLocation(e);
                        setInvalidState((prvState) => ({
                          ...prvState,
                          locationsInvalid: false,
                        }));
                      }}
                      value={selectedLocation}
                    />
                    {invalidState.locationsInvalid && (
                      <FormErrorMessage>
                        Please select user Location (s).
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl px="10px" isInvalid={invalidState.roleInvalid}>
                    <FormLabel fontSize="sm" fontmaxWeight="500">
                      User Status
                    </FormLabel>
                    <Select
                      isDisabled={!editRows}
                      colorScheme="purple"
                      options={[
                        { label: "Active", value: 1 },
                        { label: "Inactive", value: 2 },
                      ]}
                      onChange={setUserStatus}
                      value={userStatus}
                    />
                  </FormControl>
                </Flex>
              </Box>
            )}
          </Formik>

          <Flex justifyContent={"center"} mt="10px" alignItems={"center"}>
            <FormControl
              w="400px"
              isInvalid={invalidState.applicationRolesArrInvalid}
            >
              <FormLabel fontSize="sm" fontmaxWeight="500">
                Applcation
              </FormLabel>
              <Select
                // isMulti
                colorScheme="purple"
                options={ApplicationList.map((x) => {
                  return { label: x.name, value: x.id };
                })}
                onChange={updateApplication}
                value={usersApplication}
              />
              {invalidState.applicationRolesArrInvalid && (
                <FormErrorMessage>Please select user role.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              w="400px"
              ml="5px"
              isInvalid={invalidState.applicationRolesArrInvalid}
            >
              <FormLabel fontSize="sm" fontWeight="500">
                User's Roles
              </FormLabel>
              <Select
                colorScheme="purple"
                options={localRoleList.map((x) => {
                  return { label: x.name, value: x.roleId };
                })}
                onChange={setApplicationRoles}
                value={applicationRoles}
              />
              {invalidState.applicationRolesArrInvalid && (
                <FormErrorMessage>Please select user role.</FormErrorMessage>
              )}
            </FormControl>
            <Button
              mt="25px"
              ml="10px"
              borderRadius={"4px"}
              onClick={addData}
              colorScheme="blue"
            >
              Add
            </Button>
          </Flex>
          {applicationRolesArr.length > 0 && (
            <Text fontWeight={"bold"} mt="20px" fontSize={"24px"}>
              Selected Applications & Roles
            </Text>
          )}
          <Box mt="20px">
            {applicationRolesArr.map((x, i) => {
              return (
                <Flex mt='5px' justifyContent={"center"} alignItems={"center"}>
                  <Box
                    borderRadius={"4px"}
                    px="10px"
                    py="10px"
                    border="1px solid #c2c2c2"
                    w="420px"
                  >
                    <Text>{x.applicationName}</Text>
                  </Box>
                  <Box
                    borderRadius={"4px"}
                    px="10px"
                    ml="5px"
                    py="10px"
                    border="1px solid #c2c2c2"
                    w="420px"
                  >
                    <Text>{x.roleName}</Text>
                  </Box>
                  <Box
                    cursor={"pointer"}
                    onClick={() => deleteSelected(i)}
                    px="5px"
                    ml="10px"
                    py="10px"
                    w="10%"
                  >
                    <DeleteIcon />
                  </Box>
                </Flex>
              );
            })}
          </Box>
        </DrawerBody>
        <DrawerFooter mt="20px">
          <Button onClick={onClose} variant="outline" mr={3}>
            Cancel
          </Button>
         
          <Button
            isLoading={isLoading}
            ml="10px"
            // type="submit"
            onClick={handlesubmit}
            colorScheme="blue"
          >
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default Form;
