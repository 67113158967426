import { Provider } from "react-redux";
import { store } from "../redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { injectStore } from "../redux/services/axios";
import {QueryClient, QueryClientProvider} from 'react-query';
const queryClient = new QueryClient();

let persistor = persistStore(store);
injectStore(store);
export const AppProviders = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
    </QueryClientProvider>
  );
};
