import AuthReducer from "./slices/Auth";
import AdminReducer from "./slices/Admin";


import { AnyAction, Reducer } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";

const appReducer = combineReducers({
  Auth: AuthReducer,
  Admin:AdminReducer
});

export const rootReducer = (state, action) => {
  if (action.type === "AuthSlice/clearResults") {
    // this applies to all keys defined in persistConfig(s)
    storage.removeItem("persist:root");
    state = {};
  }
  return appReducer(state, action);
};
