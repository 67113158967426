import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useToast,
  ChevronDownIcon,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Textarea,
  Icon,
} from "@chakra-ui/react";
import {
  CheckboxContainer,
  CheckboxControl,
  CheckboxSingleControl,
  InputControl,
  NumberInputControl,
  PercentComplete,
  RadioGroupControl,
  ResetButton,
  SelectControl,
  SliderControl,
  SubmitButton,
  SwitchControl,
  TextareaControl,
} from "formik-chakra-ui";
import {
  AsyncCreatableSelect,
  AsyncSelect,
  CreatableSelect,
  Select,
} from "chakra-react-select";
import {
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdKeyboardDoubleArrowUp,
} from "react-icons/md";
import moment from "moment";

import { post, put } from "../../../../redux/services/api";
import { rootUrl } from "../../../../utilities/constants";
import * as Yup from "yup";
import { Formik, Field, FormikContext } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { getUsers } from "../../../../redux/slices/Admin";
import { useDispatch, useSelector } from "react-redux";

import { useMutationSaveNotes, useFtchComplainById } from "../hooks";

const getAvtar = (str) => {
  return str
    .split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), "");
};

const getTiketstatus = (id) => {
  if (id === 1) {
    return "Pending";
  } else if (id === 2) {
    return "Inprocess";
  } else if (id === 3) {
    return "Closed";
  } else if (id === 4) {
    return "Hold";
  } else {
    return "unassigned";
  }
};
const getStatusColor = (status) => {
  if (status === "Pending") {
    return "red";
  } else if (status === "Inprocess") {
    return "blue";
  } else if (status === "Closed") {
    return "green";
  } else if (status === "Hold") {
    return "yellow";
  } else {
    return "orange";
  }
};

const validationSchema = Yup.object({
  issue: Yup.string().required("Title is required"),
  description: Yup.string().required(),
});

const Form = (props) => {
  const { onClose, isOpen, editRows, selectedLocation, refetch } = props;

  const { mutateAsync, isPending } = useMutationSaveNotes();
  const {
    data,
    isFetching,
    refetch: getByIdRefresh,
  } = useFtchComplainById(editRows?.id);
  const formref = useRef();
  const dispatch = useDispatch();
  const { firstName, lastName } = useSelector((state) => state.Auth);
  const { UsersList } = useSelector((state) => state.Admin);

  const [notes, setNotes] = useState("");
  const [ViewHistory, setViewHistory] = useState(null);
  const [ticketHistory, setTicketHistory] = useState([]);
  const [ticketNote, setTicketNote] = useState([]);
  //   const toast = useToast();
  const initialValues = {
    locationId: 0,
    issue: "",
    description: "",
    attachement: "",
    ticketPriorityId: 1,
    file: "",
    userId: undefined,
    ticketStatusId: 1,
  };
  const [ticketNumber, setTicketNumber] = useState(editRows?.id);
  const [isLoading, setisLoading] = useState(false);
  const handlesubmit = () => {
    console.log({ formref });
    if (formref.current) {
      formref.current.handleSubmit();
    }
  };

  useEffect(() => {
    console.log({ UsersList });
    dispatch(getUsers());
    if (data?.result[0]) {
      let obj = data?.result[0];
      console.log({ obj });
      setTicketNumber(obj.id);
      setTicketHistory(obj.ticketHistory);
      setTicketNote(obj.ticketNote);
    }
  }, [data]);
  const onSubmit = async (data) => {
    setisLoading(true);
    if (!data.issue) {
      return;
    }
    let values = {
      ...data,
    };
    let response;
    if (ticketNumber) {
      delete values.updatedAt;
      delete values.createdAt;
      delete values.updatedBy;
      delete values.createdBy;
      // delete values.tableData;
      let payload = {
        url: `${rootUrl}/v1/Complain/${ticketNumber}`,
        data: {
          userId: values.userId,
          locationId: selectedLocation,
          issue: values.issue,
          description: values.description,
          attachement: "",
          ticketPriorityId: values.ticketPriorityId,
          file: "",
          ticketStatusId: values.ticketStatusId,
        },
      };
      try {
        setisLoading(false);
        response = await put(payload);
      } catch (err) {
        console.log({ err: err.response.data.errors[0] });

        setisLoading(false);
      }
    } else {
      let payload = {
        url: `${rootUrl}/v1/Complain`,
        data: {
          userId: values.userId,
          locationId: selectedLocation,
          issue: values.issue,
          description: values.description,
          attachement: "",
          ticketPriorityId: values.ticketPriorityId,
          file: "",
          ticketStatusId: values.ticketStatusId,
        },
      };
      try {
        setisLoading(false);
        response = await post(payload);
      } catch (err) {
        let message = err.response.data.errors[0] ?? "";
        console.log({ err: err.response.data.errors[0] });
        toast(message, {
          type: "error",
        });
        setisLoading(false);
      }
    }
    if (response?.data?.success) {
      console.log({
        response: response.data.message,
      });
      fetchList();
      onClose();
      if (editRows) {
        toast("Complain Updated Successfully", {
          type: "success",
        });
      } else {
        toast("Complain Created Successfully", {
          type: "success",
        });
      }
    }
  };
  const fetchList = () => {
    refetch();
  };
  //   console.log({ onSubmit });
  const saveNotes = async () => {
    if (notes === "") return;
    try {
      let payload = {
        notes: notes,
        ticketId: ticketNumber,
      };
      let res = await mutateAsync(payload);
      setNotes("");
      fetchList();
      getByIdRefresh();
    } catch (err) {
      console.log({ err });
    }
  };
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xl">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          {editRows ? "Update " : "Create "}
          Complain
          {ticketNumber && (
            <Text textAlign="center">Complain #{ticketNumber}</Text>
          )}
        </DrawerHeader>
        <DrawerBody>
          <Formik
            innerRef={(f) => (formref.current = f)}
            initialValues={editRows ? {...editRows,     ticketStatusId: editRows.ticketStatus?.id ?? null} : initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, values, errors, touched }) => (
              <Box p={"10px"} as="form" onSubmit={handleSubmit}>
                <Flex>
                  <InputControl
                    isReadOnly={
                      [...ticketHistory, ...ticketNote].length ? true : false
                    }
                    name="issue"
                    label="Title"
                  />
                  <SelectControl
                    ml="10px"
                    name="ticketPriorityId"
                    label="Priority"
                    selectProps={{ placeholder: "Please select  Priority" }}
                  >
                    <option value={1}>High</option>
                    <option value={2}>Medium</option>
                    <option value={3}>Low</option>
                  </SelectControl>
                </Flex>
                <Flex>
                  <SelectControl
                    label="Status"
                    name="ticketStatusId"
                    selectProps={{ placeholder: "Please select Status" }}
                  >
                    <option value={1}>Pending</option>
                    <option value={2}>Inprocess</option>
                    <option value={3}>Closed</option>
                    <option value={4}>Hold</option>
                  </SelectControl>
                  <SelectControl
                    ml="10px"
                    name="userId"
                    label="Assign to"
                    selectProps={{ placeholder: "Please select" }}
                  >
                    {UsersList.map((x) => (
                      <option key={x.id} value={x.id}>
                        {x.firstName ?? "" + x.lastName ?? ""}
                      </option>
                    ))}
                  </SelectControl>
                </Flex>

                <TextareaControl
                  isReadOnly={
                    [...ticketHistory, ...ticketNote].length ? true : false
                  }
                  name="description"
                  label="Description"
                />
              </Box>
            )}
          </Formik>
          {ticketNumber && (
            <>
              <Text fontSize={"32px"} mt="20px" fontWeight={"bold"}>
                Add Notes
              </Text>
              <Flex
                justifyContent="flex-start"
                mt="10px"
                px="10px"
                alignItems="flex-start"
              >
                <Flex w="10%">
                  <Flex
                    width="50px"
                    height="50px"
                    borderRadius="50px"
                    backgroundColor="blue.200"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text
                      textTransform="uppercase"
                      fontWeight={"bold"}
                      color={"white"}
                    >
                      {firstName[0] + lastName[0]}
                    </Text>
                  </Flex>
                </Flex>
                <Flex style={{ width: "90%" }}>
                  <Textarea
                    onChange={(e) => {
                      setNotes(e.target.value);
                    }}
                    placeholder={"Type Notes here.."}
                    value={notes}
                    // label="Notes"
                  />
                </Flex>
              </Flex>
              <Flex w="full" justifyContent={"flex-end"}>
                <Button
                  mt="5px"
                  onClick={saveNotes}
                  isLoading={isPending}
                  colorScheme="green"
                  variant="solid"
                  mr={3}
                >
                  Add
                </Button>
              </Flex>
            </>
          )}

          {[...ticketHistory, ...ticketNote].length > 0 && (
            <Text
              onClick={() => setViewHistory(!ViewHistory)}
              textDecorationLine="underline"
              fontWeight={"bold"}
              color={"blue"}
              fontSize={"32px"}
              cursor={"pointer"}
            >
              {ViewHistory ? "View Discussion" : "View History"}
            </Text>
          )}

          {ViewHistory === true ? (
            <>
              <Text mb="10px" mt="20px" fontSize={"32px"} fontWeight={"bold"}>
                Ticket History
              </Text>

              {ticketHistory.map((x, i) => {
                return (
                  <Flex mt="5px">
                    <Text fontWeight={"bold"} w="50px" bold grayColor>
                      #{i + 1}
                    </Text>
                    <Flex
                      mt="10px"
                      direction={"column"}
                      style={{
                        border: "1px solid black",
                        padding: 5,
                        borderRadius: 5,
                        marginLeft: 5,

                        backgroundColor: "#f2f2f2",
                        width: "80%",
                      }}
                    >
                      <Text body2 bold>
                        Ticket Status
                      </Text>
                      <Flex
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Flex
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 0,
                          }}
                        >
                          <Flex
                            style={{
                              width: 10,
                              height: 10,
                              marginRight: 10,
                              borderRadius: 10,
                              backgroundColor: getStatusColor(
                                getTiketstatus(x.fromTicketStatusId)
                              ),
                            }}
                          />

                          <Text>
                            {getTiketstatus(x.fromTicketStatusId) ??
                              "undefined"}
                          </Text>
                        </Flex>
                        <Text style={{ marginLeft: 5, marginRight: 5 }}>
                          ={">"}
                        </Text>
                        <Flex
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 0,
                          }}
                        >
                          <Flex
                            style={{
                              width: 10,
                              height: 10,
                              marginRight: 10,
                              borderRadius: 10,
                              backgroundColor: getStatusColor(
                                getTiketstatus(x.toTicketStatusId)
                              ),
                            }}
                          />

                          <Text>
                            {getTiketstatus(x.toTicketStatusId) ?? "undefined"}
                          </Text>
                        </Flex>
                      </Flex>
                      <Text body2 bold>
                        Assignment
                      </Text>
                      <Flex
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Text>{x.fromUser?.name ?? "unassigned"}</Text>
                        <Text style={{ marginLeft: 5, marginRight: 5 }}>
                          ={">"}
                        </Text>
                        <Text>{x.toUser?.name ?? "unassigned"}</Text>
                      </Flex>
                      <Text body2 bold>
                        Priority
                      </Text>
                      <Flex
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Icon
                          as={
                            x.fromTicketPriorityId?.id === 2
                              ? MdKeyboardArrowUp
                              : x.fromTicketPriorityId?.id === 3
                              ? MdKeyboardArrowDown
                              : MdKeyboardDoubleArrowUp
                          }
                          size={20}
                          color={
                            x.fromTicketPriorityId?.id === 2
                              ? "orange"
                              : x.fromTicketPriorityId?.id === 3
                              ? "blue"
                              : "red"
                          }
                        />

                        <Text style={{ color: "black", fontSize: 14 }}>
                          {x.fromTicketPriorityId?.id === 2
                            ? "Medium"
                            : x.fromTicketPriorityId?.id === 3
                            ? "Low"
                            : "Hight"}
                        </Text>
                        <Text style={{ marginLeft: 5, marginRight: 5 }}>
                          ={">"}
                        </Text>
                        <Icon
                          as={
                            x.fromTicketPriorityId?.id === 2
                              ? MdKeyboardArrowUp
                              : x.fromTicketPriorityId?.id === 3
                              ? MdKeyboardArrowDown
                              : MdKeyboardDoubleArrowUp
                          }
                          size={20}
                          color={
                            x.toTicketPriorityId?.id === 2
                              ? "orange"
                              : x.toTicketPriorityId?.id === 3
                              ? "blue"
                              : "red"
                          }
                        />

                        <Text style={{ color: "black", fontSize: 14 }}>
                          {x.toTicketPriorityId?.id === 2
                            ? "Medium"
                            : x.toTicketPriorityId?.id === 3
                            ? "Low"
                            : "Hight"}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                );
              })}
            </>
          ) : ViewHistory === false ? (
            <>
              <Text mb="10px" mt="20px" fontSize={"32px"} fontWeight={"bold"}>
                Discussion
              </Text>
              {ticketNote.map((x) => {
                return (
                  <Flex flexDir={"column"}>
                    <Flex key={x.id} marginTop="10px">
                      <Flex>
                        <Flex
                          width="50px"
                          height="50px"
                          borderRadius="50px"
                          backgroundColor="blue.500"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Text
                            color={"white"}
                            fontWeight={"bold"}
                            textTransform="uppercase"
                          >
                            {getAvtar(x.createdBy)}
                          </Text>
                        </Flex>
                      </Flex>
                      <Flex
                        ml="10px"
                        style={{
                          width: "100%",
                          backgroundColor: "#f2f2f2",
                          padding: 10,
                          borderRadius: 8,
                          borderWidth: 1,
                          borderColor: "GrayText",
                        }}
                      >
                        <Text>{x.notes}</Text>
                      </Flex>
                    </Flex>

                    <Flex
                      flexDir={"column"}
                      justifyContent={"flex-end"}
                      alignItems={"flex-end"}
                    >
                      <Text fontWeight={"bold"} fontSize={"12px"}>
                        {x.createdBy}
                      </Text>
                      <Text fontSize={"10px"} color={"GrayText"}>
                        {moment(moment.utc(x.createdAt).toDate()).fromNow()}
                      </Text>
                    </Flex>
                  </Flex>
                );
              })}
            </>
          ) : null}
        </DrawerBody>
        <DrawerFooter mt="20px">
          <Button onClick={onClose} variant="outline" mr={3}>
            Cancel
          </Button>

          <Button
            isLoading={isLoading}
            ml="10px"
            // type="submit"
            onClick={handlesubmit}
            colorScheme="blue"
          >
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default Form;
