import React, { useState, useEffect } from "react";
import "./assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "./layouts/auth";
import AdminLayout from "./layouts/admin";
import { useDispatch, useSelector } from "react-redux";
import { getApplication, getRoles } from "../src/redux/slices/Admin";

const PageNotExists = () => {
  return <h1>404</h1>;
};

const App = () => {
  const [initialized, setInitialized] = useState(false);

  const dispatch = useDispatch();
  const { isAuthenticated, jwtToken, userId, logo } = useSelector(
    (state) => state.Auth
  );

  useEffect(() => {
    if (isAuthenticated) {
      console.log({isAuthenticated,jwtToken})
      dispatch(getApplication());
      dispatch(getRoles());

    }
  }, []);

  return (
    <HashRouter>
      <Switch>
        {isAuthenticated ? (
          <Route path={`/admin`} component={AdminLayout} />
        ) : (
          <Route path={`/auth`} component={AuthLayout} />
        )}

        {isAuthenticated ? (
          <Redirect from="/" to="/admin/default" />
        ) : (
          <Redirect from="/" to="/auth" />
        )}
        <Route path={`/*`} component={PageNotExists} />
      </Switch>
    </HashRouter>
  );
};
export default App;
